<template>
  <div class="dashboard workout-by-page workout-by-list-page">
    <div class="workout-by-list-heading-wrapper">
      <div class="container">
        <div class="workout-by-list-heading d-flex justify-content-between align-items-center">
          <div class="d-none d-xl-flex justify-content-start align-items-center">
            <button type="button" class="heading-back">
              <chevronLeft/>
            </button>
            <p class="section-title uppercase">Workshops</p>
          </div>
          <div class="workout-by-list-options d-flex align-items-center justify-content-xl-end">
<!--            <div class="order-select-wrapper"> -->
<!--              <label class="d-flex justify-content-center align-items-center"> -->
<!--                <div class="relative"> -->
<!--                  <select> -->
<!--                    <option>Ordenar</option> -->
<!--                    <option>1</option> -->
<!--                    <option>2</option> -->
<!--                    <option>3</option> -->
<!--                  </select>-->
<!--                  <div class="order-select-icon">-->
<!--                    <orderIcon/>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </label>-->
<!--            </div>-->
            <button type="button" class="filters-btn d-flex justify-content-center align-items-center" @click="toggleFilters()">
              <div class="filters-btn-icon">
                <filtersIcon/>
              </div>
              <p class="filters-btn-text">Filtros<span v-if="filtersCount > 0"> ({{ filtersCount }})</span></p>
            </button>
          </div>
        </div>

      </div>
    </div>
    <filters/>
    <div class="workout-by-list">
      <div class="container">
        <div class="row workout-by-list-row" v-if="showWorkoutList">
          <div class="col-12 col-lg-4 col-xxl-3 workout-by-list-column" v-for="(item,index) in workshopItems" :key="index">
            <workshopItem class="item-mobile-layout-2" :workshopItem="item"/>
          </div>
        </div>
        <p v-if="!showWorkoutList">Sem resultados</p>
      </div>
    </div>
  </div>
</template>

<script>
//Components
import workshopItem from "@/components/workshop/workshop-item.vue";
import chevronLeft from "@/components/svg/chevron-left.vue";
import filtersIcon from "@/components/svg/filters.vue";
import orderIcon from "@/components/svg/order-icon.vue";
import filters from "@/components/filters.vue";
import {EventBus} from "@/event-bus";
import axios from "axios";

export default {
  name: "workshop-by-list",
  data() {
    return {
      lang: this.$root.siteLang,
      workshopItems: [],
      showWorkoutList: false,
      pageTitle: this.$route.params.pageTitle,
      filtersCount: '',
    }
  },
  components : {
    workshopItem,
    chevronLeft,
    filtersIcon,
    orderIcon,
    filters
  },
  methods : {
    toggleFilters() {
      let element = document.getElementsByClassName("filters-wrapper")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
      } else {
        element.classList.add("open");
      }
    },
    loadProducts (authKey, parentFilter, duration, level) {
      axios
          .get(process.env.VUE_APP_API_URL + 'products?id_category=2&with_category_title=0'+ parentFilter + duration + level +'&lang=' + this.lang,{
            headers: {
              Authorization: 'Bearer ' + authKey
            }
          })
          .then((response) => {
            this.workshopItems = response.data;
          })
          .catch((e) => {
            this.errors.push(e);
          }).finally((e) => {
        (this.workshopItems.length > 0) ? this.showWorkoutList = true : this.showWorkoutList = false;
      });
    }

  },
  mounted() {
    let authKey = window.$cookies.get("authKey");
    let parentFilter = '';

    this.loadProducts(authKey, parentFilter);

    EventBus.$on('bus-toggleFilters', () => {
      let element = document.getElementsByClassName("filters-wrapper")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
      } else {
        element.classList.add("open");
      }
    });

    EventBus.$on('bus-changeFilters', (activeFiltersCount, duration, level) => {
      let durationFilter = '';
      let levelFilter = '';

      if ( activeFiltersCount > 0 ) {
        this.filtersCount = activeFiltersCount;
      }

      if ( duration != '' && duration != undefined ) {
        durationFilter = '&duration=' + duration;
      }

      if ( level != '' && level != undefined ) {
        levelFilter = '&level=' + level;
      }

      this.loadProducts(authKey, parentFilter, durationFilter, levelFilter);
    });

    EventBus.$on('bus-clearFilters', () => {
      this.filtersCount = 0;

      let duration = '';
      let level = '';

      this.loadProducts(authKey, parentFilter, duration, level);
    });
  },
}
</script>