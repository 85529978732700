<template>
  <div class="workout-item" v-if="!!workshopItem.media.general">
    <router-link :to="{ name: 'workshop-detail', params:{id:$root.getLink(workshopItem)}}" class="workout-item-link">
      <div class="workout-item-image">
        <img :src="workshopItem.media.general[0].image" alt="">
      </div>
      <div class="workout-item-top">
        <div class="d-flex justify-content-between align-items-center">
          <div class="workout-item-flag-wrapper">
            <div class="workout-item-flag d-flex justify-content-center align-items-center" v-if="workshopItem.price > 0">
              <div class="workout-item-flag-icon d-flex justify-content-center align-items-center d-lg-inline-block">
                <lock2/>
              </div>
              <p class="d-none d-lg-block">{{workshopItem.price + ' Creditos'}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="workout-item-content">
        <p class="workout-item-title">{{ workshopItem.title }}</p>
        <div class="workout-item-tags">
          <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
            <div class="workout-item-tag-item-icon d-none d-lg-flex justify-content-center align-items-center">
              <clock/>
            </div>
            <p>{{ workshopItem.duration }}</p>
          </div>
<!--          <div class="workout-item-tag-item d-flex justify-content-center align-items-center">-->
<!--            <div class="workout-item-tag-item-icon d-none d-lg-flex justify-content-center align-items-center">-->
<!--              <level/>-->
<!--            </div>-->
<!--            <p>{{ workshopItem.itensity }}</p>-->
<!--          </div>-->
          <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
            <div class="workout-item-tag-item-icon d-none d-lg-flex justify-content-center align-items-center">
              <fire/>
            </div>
            <p>{{ workshopItem.level }}</p>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import bookmark from "@/components/svg/bookmark.vue";
import lock2 from "@/components/svg/lock2.vue";
import clock from "@/components/svg/clock.vue";
import level from "@/components/svg/level.vue";
import fire from "@/components/svg/fire.vue";

export default {
  name: "workout-item",
  components : {
    bookmark,
    lock2,
    clock,
    level,
    fire
  },
  props: {
    workshopItem: {
      required: true,
    },
  },
}
</script>