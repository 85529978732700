<template>
  <div class="filters-wrapper">
    <div class="container">
      <div class="filters d-flex justify-content-between flex-column d-xl-block">
        <div>
          <div class="filters-top d-flex justify-content-center align-items-center d-xl-none">
            <button class="close-filters-btn" @click="toggleFilters()">
              <closeIcon/>
            </button>
            <div class="filters-top-logo">
              <LogoBoxSVG/>
            </div>
          </div>
          <div class="filters-heading d-xl-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-center align-items-center flex-column justify-content-xl-start flex-xl-row">
              <p class="section-title uppercase">FILTROS</p>
              <!--<p class="filters-results-count">4 Treinos Disponíveis</p>-->
            </div>
            <div class="d-none d-xl-block">
              <button type="button" class="clear-filters-link" @click="clearFilters">Limpar Filtros</button>
            </div>
          </div>
          <div class="filters-content">
            <div class="row">
              <div class="col-12 col-xl-6 col-xxl-5">
                <div class="filters-item">
                  <p class="filters-item-title">Duração</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="filters-item-radio-wrapper" v-for="(item,index) in filters.duration" :key="index">
                      <label>
                        <input type="radio" name="duration" v-model="duration" :value="index" @change="changedFilters">
                        <div class="filters-item-radio-btn">
                          <p>{{ item }}</p>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl-6 col-xxl-3">
                <div class="filters-item">
                  <p class="filters-item-title">Nível</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="filters-item-radio-wrapper" v-for="(item,index) in filters.level" :key="index">
                      <label>
                        <input type="radio" name="level" v-model="level" :value="index" @change="changedFilters">
                        <div class="filters-item-radio-btn">
                          <p>{{ item }}</p>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="filters-btn-wrapper d-flex justify-content-between align-items-center d-xl-none">
          <button class="generic-btn grey-gradient smaller-padding" @click="clearFilters">Limpar Filtros</button>
          <button class="generic-btn red-gradient smaller-padding" @click="toggleFilters()">Ver Treinos</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoBoxSVG from "@/components/svg/logo-black.vue";
import closeIcon from "@/components/svg/close.vue";
import {EventBus} from "@/event-bus";
import axios from "axios";

export default {
  name: "filters",
  data() {
    return {
      errors:[],
      filters: [],
      lang: this.$root.siteLang,
      duration: '',
      level: '',
    }
  },
  components : {
    LogoBoxSVG,
    closeIcon
  },
  methods: {
    toggleFilters: function() {
      EventBus.$emit("bus-toggleFilters");
    },
    changedFilters: function() {
      let activeFiltersCount = document.querySelectorAll('.filters-item input[type="radio"]:checked').length;

      EventBus.$emit("bus-changeFilters", activeFiltersCount, this.duration, this.level);
    },
    clearFilters: function() {
      this.duration = '';
      this.level = '';

      EventBus.$emit("bus-clearFilters");
    }
  },
  mounted() {
    axios
        .get(process.env.VUE_APP_API_URL + 'products/filters?lang=' + this.lang)
        .then((response) => {
          this.filters = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
  }
}
</script>