<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
    <path fill="#777" d="M22 5.565v1.711h-2.958c-.343.905-1.223 1.565-2.25 1.565s-1.907-.66-2.25-1.565H2V5.565h12.543C14.885 4.66 15.765 4 16.793 4c1.026 0 1.906.66 2.249 1.565H22zm-5.208 9.878c-1.027 0-1.907.66-2.25 1.564H2v1.712h12.543c.342.904 1.222 1.565 2.25 1.565 1.026 0 1.906-.66 2.249-1.565H22v-1.712h-2.958c-.343-.904-1.223-1.564-2.25-1.564zM7.452 9.72c-1.027 0-1.907.66-2.25 1.565H2v1.712h3.203c.342.904 1.222 1.564 2.25 1.564 1.026 0 1.906-.66 2.249-1.564H22v-1.712H9.702c-.343-.905-1.223-1.565-2.25-1.565z" transform="translate(-1668 -152) translate(1666 148)"/>
  </svg>
</template>

<script>
export default {
name: "filters"
}
</script>