<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
    <path fill="#777" d="M9 3L5 6.99h3V14h2V6.99h3L9 3zm7 14.01V10h-2v7.01h-3L15 21l4-3.99h-3z" transform="translate(-1559 -151) translate(1554 148)"/>
  </svg>
</template>

<script>
export default {
name: "order-icon"
}
</script>